@import url("https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@200;300;400;500;600;700&display=swap");
$gray: #b4b4b4;
$blue-dark: #025184;
:root {
  --gray: rgba(24, 129, 225, 0.4);
}

* {
  scrollbar-width: auto;
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

body {
  font-family: "M PLUS 1", sans-serif;
}

/* Firefox */



/* Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #dbd5d5c9;
  border-radius: 10px;
  border: 2px solid #ffffff;
  &:hover {
    background-color: #767ffe;
  }
}

.icon {
  stroke: unset;
  stroke-width: 0px;
  fill: currentColor;
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
}

.icon-1p25 {
  transform: scale(1.25);
}

.icon-1p30 {
  transform: scale(1.3);
}

.icon-1p35 {
  transform: scale(1.35);
}

.icon-1p50 {
  transform: scale(1.5);
}

.icon-2x {
  transform: scale(2);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

.l {
  box-shadow: inset 0 0 0 1px red;
}

.text-gray {
  color: #a3a3a3;
}

.text-green {
  color: #87c06b;
}

.text-blue {
  color: #102abc;
}

.text-orange {
  color: #ffb84e;
}

.text-lightblue {
  color: #407bff;
}

.bg-gray {
  background: #f9f9f9 !important;
}

.bg-lightgreen {
  background: #e3fad7;
}

.bg-blue {
  background: #102abc;
}

.bg-lightblue {
  background: #eaeeff;
}

.bg-green {
  background: #87c06b;
}

.bg-orange {
  background: #ffb84e;
}

.bg-lightorange {
  background: #fff9ef;
}

.fs-9 {
  font-size: 9px;
}

.fs-14 {
  font-size: 14px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fs-40 {
  font-size: 40px;
}

.fs-50 {
  font-size: 50px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.mxw-250 {
  max-width: 250px;
}

.w30px {
  width: 30px;
}

.h30px {
  height: 30px;
}

.w10 {
  width: 10%;
}

.w90 {
  width: 90%;
}

.w-200px {
  width: 200px;
}

.wh-20px {
  width: 20px !important;
  height: 20px !important;
}

.wh-40px {
  width: 40px !important;
  height: 40px !important;
}

.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  position: fixed !important;
  top: 0;
  right: 0;
  // background: rgba(255, 255, 255, 0.75);
  // backdrop-filter: blur(6px);
  background: #ebeeff;
  z-index: 20;
}

.nav-btn {
  left: 0;
  top: 60px;
  position: sticky;
  margin-top: -40px;
}

.logout-btn {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: #0e2074;
  transition: 200ms;
  &:hover {
    background: #0e2074;
    border: 1px solid #0e2074;
    color: #ffffff;
  }
}

.logout-btn-line {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 4px 12px;
  color: #ffffff;
  transition: 200ms;
  &:hover {
    background: #0e2074;
    border: 1px solid #0e2074;
    color: #ffffff;
  }
}

.wrapper-box {
  padding-top: calc(62px + 10px);
}

aside {
  width: 280px;
  height: calc(100vh - 62px);
  background: #fdfdfd;
  border: 1px solid #f1f1f1;
  // background: red;
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow-y: auto;
}

.wrapper {
  min-height: 100vh;
  margin-left: calc(280px + 15px);
  // padding-top: 70px;
  padding-top: 62px;
  padding-right: 15px;
  position: relative;
  z-index: 18;
  // background: red;
}



.grd-btn {
  flex: 1 1 auto;
  display: flex;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    #0e1f6e 0%,
    #246b9a 51%,
    #112edb 100%
  );
  padding: 10px 20px;
  border: none;
}

.grd-btn:hover {
  background-position: right center !important;
}
.grd-btn:disabled {
  background-image: none;
  background-color: lightgray;
}



.signin-box {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
}

.signin-left {
  background: #ffffff;
  height: 100%;
  min-height: 100%;
  // max-height: 100vh;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.signin-right {
  background: #f9f9f9;
  height: 100%;
  width: 100%;
  padding: 80px 80px 40px 80px !important;
  .btn-box {
    background: #eeeeee;
    border-radius: 6px;
    padding: 8px;
    button {
      background: transparent;
      border: none;
      padding: 8px;
      border-radius: 5px;
      &:hover,
      :active {
        background: #ffffff;
      }
    }
  }
  .anchor {
    a {
      text-decoration: none;
      color: #055687;
    }
    .line {
      text-decoration: underline;
    }
  }
}

.input-shadow {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
}

::placeholder {
  color: #e0e0e0 !important;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e0e0e0;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e0e0e0;
}

.back-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  &:hover {
    color: #e2b211;
  }
}

.border-btn {
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  text-decoration: underline;
  &:hover {
    color: #e2b211;
  }
}

// ========new code
.card-btn {
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: 0px 4px 40px rgba(22, 5, 126, 0.12);
  border-radius: 10px;
  background: #ffffff;
  &:hover {
    transition: 1000ms;
    box-shadow: 0px 4px 40px rgba(41, 28, 85, 0.308);
  }
}

.card-box {
  width: 362px;
  height: 292px;
  .icon-box {
    width: 92px;
    height: 92px;
    background: #f2f4ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

hr {
  margin: 0.5rem 0;
  // color: #F1F1F1 !important;
  color: #d8d7d7 !important;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

// ====Accordion=====
.accordion-collapse {
  border: none !important;
}

.accodion-btn {
  .accordion-button {
    font-weight: 700;
    font-size: 20px;
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #fdfdfd;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }
  .accordion-item {
    background-color: #fdfdfd !important;
    // background: #FDFDFD;
    border: none;
  }
  // =========
  .accordion {
    --bs-accordion-color: #212529;
    --bs-accordion-bg: #fdfdfd;
    --bs-accordion-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: none;
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #212529;
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e);
    --bs-accordion-btn-focus-box-shadow: none !important;
    // --bs-accordion-active-color: #e2b211;
    --bs-accordion-active-color: #0c63e4;
  }
}

.aside-form {
  input {
    border: none;
    outline: none;
    padding: 0;
    background: none;
  }
  .aside-img {
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.border-card {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
}

.quotes-btn {
  font-weight: 600;
  border: 1px solid #0e2181;
  color: #0e2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 120px;
  .icon {
    font-size: 28px !important;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 200ms;
  }
}
.quotes-btn2{
  font-weight: 600;
  border: 1px solid #0e2181 !important;
  color: #0e2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  width: 50px;
  margin-bottom: 4px;
  .icon {
    font-size: 28px !important;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 200ms;
  }
}
// =====tab btn
.nav-link {
  background: none;
  border: 1px solid #000000 !important;
  border-radius: 29px !important;
  color: #2a2a2a !important;
  padding: 8px 20px !important;
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 400ms;
  }
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
}

.table > :not(caption) > * > * {
  border: none;
  padding: 0 !important;
  padding: 10px 20px !important;
  overflow-x: scroll;
}

.repeat-table {
  table {
    thead {
      tr {
        th {
          padding-left: 15px !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 0px !important;
          padding-left: 15px !important;
          vertical-align: middle;
          &:nth-child(1) {
            font-weight: 600;
            font-size: 22px;
          }
        }
      }
      tr:nth-child(odd) {
        border: 1px solid #e2e2e2 !important;
        background: #fbfbfb;
      }
    }
  }
}

.tracking-box {
  width: 500px;
  height: 320px;
  img {
    width: 100%;
    object-fit: contain;
  }
}

.add-btn {
  background: #eaeeff;
  padding: 12px 14px;
  border-radius: 32px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 22px;
    color: #0d1950;
  }
  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0d1950;
    color: #ffffff;
    margin-right: 10px;
  }
  &:hover {
    background: #79aced;
    transition: 400ms;
    span {
      color: #ffffff;
    }
  }
}

.tracking-modal {
  .modal-header {
    border-bottom: none !important;
  }
}

.input-box {
  input {
    border: none;
    outline: none;
    border-bottom: 0.5px solid #ccc;
    padding: 10px 0;
  }
  ::placeholder {
    color: #2a2a2a !important;
  }
}

.booking-box {
  .track-icon {
    align-self: center;
    // height: 100%;
    position: relative;
    width: 24px;
    // .icon {
    //   position: absolute;
    //   bottom: 3px;
    // }
  }
}

.border-left {
  .left-border:not(:first-child) {
    border-left: 1px solid #e0e0e0;
  }
}

// dynamic steper
.number-grid {
  list-style: none;
  // counter-reset: num-counter;
}

.number-grid > div {
  // counter-increment: num-counter;
  position: relative;
  padding-bottom: 70px;
  .icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 2;
  }
}

.number-grid > div::before {
  // content: counter(num-counter);
  content: "";
  color: #4e2a14;
  position: absolute;
  left: 0;
  width: 60px;
  height: 60px;
  background: #eef1ff;
  // border: 1px solid #FBB522;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  font-weight: 600;
  z-index: 1;
}

.number-grid > div::after {
  content: "";
  position: absolute;
  left: 30px;
  top: 10px;
  width: 1px;
  height: 100%;
  // background-color: #E0E0E0;
  border: #e0e0e0;
  border-left-style: dotted;
  z-index: 0;
}

.number-grid > div:last-child::after {
  display: none;
}

.plus-btn {
  display: flex;
  align-items: center;
  height: 50px;
  button {
    background: none;
    border: none;
    padding: 0;
    margin-left: 20px;
    &:hover {
      color: #e2b211;
    }
  }
}

.pan-btn {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #f1f1f1;
  li {
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
  }
  button {
    width: 100%;
    background: none;
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    text-align: start;
    padding-left: 50px;
    &:hover,
    :active {
      background: #eef1ff;
    }
  }
}

.crm-box {
  ul {
    list-style-type: none;
    padding: 0;
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 12px;
    li {
      padding: 5px 8px;
      button {
        border: none;
        background: none;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        &:hover {
          background: #f8f8f8;
          border-radius: 8px;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ededed;
      }
    }
  }
}

.quill {
  .ql-toolbar {
    background: #fdfdfd;
    border-radius: 15px 15px 0 0;
  }
  .ql-toolbar.ql-snow {
    border: 1px solid #f1f1f1;
  }
  .ql-container.ql-snow {
    border: 1px solid #f1f1f1;
  }
  .ql-container {
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    height: 100%;
    min-height: 100px;
    margin: 0px;
    position: relative;
    border-radius: 0 0 15px 15px;
  }
}

.content-card {
  border: 1px solid #f1f1f1;
  border-radius: 15px;
  overflow: hidden;
  .br-bottom {
    border-bottom: 1px solid #f1f1f1;
    background: #fdfdfd;
  }
}

.upload-btn {
  color: #ffffff !important;
  background: linear-gradient(90deg, #0e2078 -0.3%, #112ed8 130.42%);
  border: 1px solid #112ed8 !important;
  border-radius: 29px;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: fit-content;
}
.cancel-btn {
  margin-top: 4px;
  color: #000000 !important;
  background: #fff;
  border: 1px solid #000000 !important;
  border-radius: 29px;
  font-size: 14px;
  font-weight: 600;
  padding: 9px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

// uploader
.upload-box {
  background: #f2f6f9;
  // background: #FDFDFD;
  border-radius: 8px;
  h3,
  h6 {
    display: flex;
    justify-content: center;
    color: #949494;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
  }
  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 30px;
  }
}

.drag-box {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 50px;
  border: 2px dashed rgba(148, 148, 148, 0.7);
  border-radius: 6px;
  span {
    position: relative;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #949494;
    pointer-events: none;
    padding-top: 10px;
    
    a {
      color: #1881e1;
    }
  }
  input {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

.file-import {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f2f6f9;
  // background: #FDFDFD;
  padding: 7px 9px;
  .file-importicon {
    width: 34px;
    min-width: 34px;
    height: 34px;
    margin: 5px;
    margin-right: 10px;
    &[data-src$=".pdf"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/><path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/></svg>')
        no-repeat;
    }
    &[data-src$=".png"],
    &[data-src$=".gif"],
    &[data-src$=".jpg"],
    &[data-src$=".jpeg"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" viewBox="0 0 16 16"><path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/><path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/></svg>')
        no-repeat;
    }
    &[data-src$=".doc"],
    &[data-src$=".docx"] {
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%236FB0EB" class="bi bi-file-earmark-word" viewBox="0 0 16 16"><path d="M5.485 6.879a.5.5 0 1 0-.97.242l1.5 6a.5.5 0 0 0 .967.01L8 9.402l1.018 3.73a.5.5 0 0 0 .967-.01l1.5-6a.5.5 0 0 0-.97-.242l-1.036 4.144-.997-3.655a.5.5 0 0 0-.964 0l-.997 3.655L5.485 6.88z"/><path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/></svg>')
        no-repeat;
    }
  }
  span {
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  button {
    background-color: transparent;
    border: none;
    margin-right: 10px;
    color: #6a738d;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
  .icon {
    cursor: pointer;
    &:hover {
      color: blue;
    }
    &:active {
      color: green;
    }
  }
}

/* Custom Modal CSS */
.custom-modal {
  max-width: 70vw !important;
  min-height: 90vh !important;

  background-color: #000000;
}

/* Adjust the iframe to fill the modal */
.modal-body-pdf {
  min-height: 100% !important;
  padding: 0; /* Remove default padding for Modal.Body */
}

.vision {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  .icon {
    color: #515456;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
  button {
    padding: 0;
    margin-left: 20px;
    border: none;
    background: none;
    &:hover {
      color: #0E2078;
    }
  }
  .delete-btn {
    &:hover {
      color: red;
    }
  }
}
.vendor-tbl {
  table {
    thead {
      tr {
        th {
          padding-left: 15px !important;
          vertical-align: middle;
          min-width: 180px;
          max-width: 220px;
          font-size: 16px !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 5px !important;
          vertical-align: middle;
        }
      }
      tr:nth-child(odd) {
        border: 1px solid #E2E2E2 !important;
        background: #FBFBFB;
      }
    }
  }
}
.sku-tbl {
  table {
    thead {
      tr {
        th {
          // padding-left: 15px !important;
          vertical-align: middle;
          min-width: 100px;
          max-width: 220px;
          font-size: 16px !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 4px !important;
          vertical-align: middle;
        }
      }
      tr:nth-child(odd) {
        border: 1px solid #E2E2E2 !important;
        background: #FBFBFB;
      }
    }
  }
}

.edit-btn {
  &:hover {
    color: #0E2078;
  }
}


.content-wrapper {
  width: 800px;
  margin: 0 auto;
}
// =======new css
.tabs-row {
  width: 100%;
  overflow-x: auto;
  ul {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    .nav-link {
      white-space: nowrap;
    }
  }
}
.btn-line {
  background: none;
  padding: 0;
  border: none;
  text-decoration: underline;
  color: #5073b6;
  font-weight: 700;
  font-size: 20px;
  &:hover {
    color: #0c1faa;
    transition: 500ms;
  }
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
}
input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: black !important;
}

.ant-picker-dropdown {
  z-index: 2000; /* Make sure it's higher than the modal's z-index */
}


//sku footprint css
.sku-footprint {
  table {
    thead {
      tr {
        th {
          text-align: left !important;
          font-weight: 600 !important;
          font-size: 12px !important;
          word-break: break-all;
          vertical-align: text-top;
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
      }
    }
    tbody {
      tr {
        // background: lightcoral;
        td {
          vertical-align: top;
          padding-bottom: 15px !important;
          position: relative;
          i {
            position: absolute;
            font-weight: 400;
            font-size: 12px;
            padding-top: 2px;
          }
          input {
            padding-left: 5px;
            text-align: center;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type=number] {
            -moz-appearance: textfield;
          }
        }
      }
      tr:nth-child(odd) {
        border: none !important;
        background: none;
      }
    }
  }
}

.tracking-btn {
  font-weight: 600;
  border: 1px solid #0e2181;
  color: #0e2181;
  background: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: max-content;
  .icon {
    font-size: 28px !important;
    position: absolute;
    right: 40px;
  }
  &:hover {
    background: linear-gradient(
      90deg,
      #0e2078 -0.3%,
      #112ed8 130.42%
    ) !important;
    border: 1px solid #112ed8 !important;
    color: #ffffff !important;
    transition: 200ms;
  }
}